/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { last, map, size, slice } from 'lodash';
import PropTypes from 'prop-types';

import { MiniProduct } from './components';
import { MiniProductGridContext } from './contexts';
import { usePriceProducts } from '../../hooks';
import useInventoryMessaging from 'app/common/components/ItemAvailability/hooks/useInventoryMessaging.js';

/**
 * Render component for a grid of `MiniProduct` cards.
 *
 * @visibleName Mini Product Grid
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const MiniProductGrid = ({
  breadcrumbs,
  containerName,
  products,
  useGrid = true
}) => {
  useInventoryMessaging();
  const context = React.useMemo(
    () => ({
      breadcrumbs: slice(
        breadcrumbs,
        0,
        Math.max(size(breadcrumbs) - 1, 0)
      ).concat([
        {
          ...(last(breadcrumbs) || {}),
          uri: window.location.pathname
        }
      ]),
      containerName,
      products,
      usingGrid: useGrid
    }),
    [breadcrumbs, containerName, products, useGrid]
  );
  let gridClasses = 'flex-wrap sm:-m-3';
  if (products.length < 4) {
    gridClasses =
      'flex-wrap lg:flex-row sm:-m-3 lg:justify-center lg:items-center lg:flex-wrap';
  }
  const classNameArg = useGrid
    ? classNames('w-full sm:flex ' + gridClasses)
    : classNames('w-full sm:flex flex-col');

  const prices = usePriceProducts(products);

  return (
    <MiniProductGridContext.Provider value={context}>
      <ul className={classNameArg}>
        {map(products, (product, i) => (
          <MiniProduct
            className={classNames('max-w-md')}
            key={`product-${product.id}`}
            product={product}
            position={i}
            pricing={prices.get(product.id)}
          />
        ))}
      </ul>
    </MiniProductGridContext.Provider>
  );
};

MiniProductGrid.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  /** Whether to show the grid as a grid or list */
  useGrid: PropTypes.bool
};

export default MiniProductGrid;
export { MiniProductGrid };
