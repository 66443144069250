import { useFormatMessage } from 'app/common/hooks/';
import { get, isEmpty } from 'lodash';
import messages from '../ItemAvailability.messages';
import { useContentInfo, useFetchContent } from 'app/content/hooks';
import { useContext, useEffect, useMemo, useState } from 'react';
import * as RequestStatus from 'app/common/hooks/useRestApi/RequestStatus.js';
import ContentModelContext, {
  addContentModel,
  getContentModel,
  useGetContentModelKeyGens
} from 'app/content/contexts/ContentModelContext.js';

const useInventoryMessaging = () => {
  const { inventoryMessagingModel: modelName } = useContentInfo();
  const fetchRestApiState = useFetchContent({
    modelName,
    sendImmediately: false
  });

  useExecuteContentRequest({ fetchRestApiState, modelName });
};

function useExecuteContentRequest({ fetchRestApiState, modelName }) {
  const { error, exception, response, requestStatus, sendCallback } =
    fetchRestApiState;
  const [inventoryMessages, setInventoryMessages] = useState({});
  const [done, setDone] = useState(false);
  const contentModelContext = useContext(ContentModelContext);
  const contentModelKeyGens = useGetContentModelKeyGens();
  const fallbackMessages = useFallbackMessages();
  const contentKey = getModelKeyGen(contentModelKeyGens);

  useEffect(() => {
    if (
      requestStatus === RequestStatus.IN_PROGRESS ||
      isValid(inventoryMessages) ||
      done ||
      isEmpty(contentModelKeyGens)
    ) {
      return;
    } else if (requestStatus === RequestStatus.COMPLETE) {
      if (error || get(response, 'results', []).length === 0) {
        setInventoryMessages(fallbackMessages);
      } else {
        setInventoryMessages(response.results[0]);
      }
    } else if (requestStatus === RequestStatus.PENDING) {
      const existing = getContentModel({
        name: modelName,
        keyGen: contentKey,
        contentModelContext
      });
      if (existing && isValid(existing)) {
        setInventoryMessages(existing);
      } else {
        sendCallback();
      }
    }
  }, [
    response,
    requestStatus,
    error,
    exception,
    inventoryMessages,
    modelName,
    sendCallback,
    contentModelContext,
    contentKey,
    contentModelKeyGens,
    done,
    fallbackMessages
  ]);

  useEffect(() => {
    if (done || !isValid(inventoryMessages)) {
      return;
    }
    addContentModel({
      name: modelName,
      content: inventoryMessages,
      keyGen: contentKey,
      contentModelContext
    });
    setDone(true);
  }, [inventoryMessages, modelName, contentKey, contentModelContext, done]);
}

function isValid(content) {
  return (
    !isEmpty(content) &&
    !isBlank(get(content, 'fields.inStockLabel')) &&
    !isBlank(get(content, 'fields.outOfStockLabel'))
  );
}

function isBlank(str) {
  return str === '';
}

function useFallbackMessages() {
  const formatMessage = useFormatMessage();
  return {
    fields: {
      inStockLabel: {
        name: 'inStockLabel',
        value: formatMessage(messages.availability, {
          isAvailable: true
        })
      },
      outOfStockLabel: {
        name: 'outOfStockLabel',
        value: formatMessage(messages.availability, {
          isAvailable: false
        })
      }
    }
  };
}

const useGetInventoryMessageForProduct = product => {
  const contentModelKeyGens = useGetContentModelKeyGens();
  const keyGen = getModelKeyGen(contentModelKeyGens);
  const contentModelContext = useContext(ContentModelContext);
  const [value, setValue] = useState();
  const { inventoryMessagingModel: name } = useContentInfo();
  const fallbackValue = useFallbackMessages();

  const available = get(product, 'inventorySummary.available', false);
  const hasQuantity = get(product, 'inventoryQty', 0) > 0;
  const isAvailable = available || hasQuantity;

  useEffect(() => {
    if (!contentModelContext || isEmpty(contentModelKeyGens)) {
      return;
    }
    const inventoryMessageContent = getContentModel({
      name,
      keyGen,
      contentModelContext,
      fallbackKeys: getFallbackKeys(contentModelKeyGens),
      fallbackValue
    });
    setValue(inventoryMessageContent);
  }, [name, keyGen, contentModelContext, contentModelKeyGens, fallbackValue]);

  return useMemo(() => {
    if (value) {
      return getInventoryMessage(value, isAvailable);
    }
    return undefined;
  }, [value, isAvailable]);
};

function getFallbackKeys(contentModelKeyGens) {
  const fallbackKeys = [];
  if (contentModelKeyGens.dealerNetwork) {
    fallbackKeys.push(contentModelKeyGens.dealerNetwork);
  }
  if (contentModelKeyGens.tenant) {
    fallbackKeys.push(contentModelKeyGens.tenant);
  }
  return fallbackKeys;
}

function getModelKeyGen(contentModelKeyGens) {
  if (contentModelKeyGens.currentStore) {
    return contentModelKeyGens.currentStore;
  } else if (contentModelKeyGens.dealerNetwork) {
    return contentModelKeyGens.dealerNetwork;
  } else {
    return contentModelKeyGens.tenant;
  }
}

function getInventoryMessage(content, isAvailable) {
  if (isAvailable) {
    return get(content, 'fields.inStockLabel.value', '');
  } else {
    return get(content, 'fields.outOfStockLabel.value', '');
  }
}

export default useInventoryMessaging;
export { useGetInventoryMessageForProduct };
