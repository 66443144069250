import { createContext, useContext, useMemo } from 'react';
import { get, isEmpty } from 'lodash';
import { useNationalSiteContext } from 'app/common/hooks';
import { LocalStorageCache } from 'app/common/services';
import { LocaleContext, PreviewContext } from 'app/common/contexts';

const ContentModelContext = createContext({});

const ContentModelCache = new LocalStorageCache('ContentModelCache', {
  enableLocal: true,
  enableSession: true,
  ttlSeconds: 120
});

/**
 * Provider for the ContentModelContext. The context simply contains
 * an empty object that is used to store content model data.
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
const ContentModelProvider = ({ children }) => {
  const loadFromCache = () => {
    const keys = ContentModelCache.keys();
    const cache = {};
    keys.forEach(key => {
      if (ContentModelCache.get(key)) {
        cache[key] = ContentModelCache.get(key);
      }
    });
    return cache;
  };
  return (
    <ContentModelContext.Provider value={loadFromCache()}>
      {children}
    </ContentModelContext.Provider>
  );
};

/**
 * @typedef {Object} ContentModelKeyGen Represents the values to use when generating a content model key. All values are optional.
 * If supplied, the values will be used to generate a key for the content model.
 * @property {string | undefined} applicationId - The current site id.
 * @property {string | undefined} currentStoreId - The currently selected store id.
 * @property {string} locale - The current locale.
 * @property {boolean} isPreview - Is this admin preview mode?
 */

/**
 * Add a content model to the context.
 * @param name {string} - the name of the content model
 * @param content {ContentResolverResponse} - the content model data. This should be the response from the content request.
 * @param keyGen {ContentModelKeyGen | {}} - The key generation values.
 * @param contentModelContext {Object} - the content model context.
 */
const addContentModel = ({
  name,
  content,
  keyGen = {},
  contentModelContext
}) => {
  if (!contentModelContext || isPreview(keyGen) || isEmpty(content)) {
    return;
  }
  const key = generateContentKey(name, keyGen);
  contentModelContext[key] = content;
  ContentModelCache.put(key, content);
};

/**
 * Get a content model from the context
 * @param name {string} The name of the content model
 * @param keyGen {ContentModelKeyGen | {}} The key generation values. Optional.
 * @param contentModelContext {Object} The content model context.
 * @param fallbackKeys {string[]} The fallback keys to use if the content model is not found. Ordered by priority.
 * @param fallbackValue {ContentResolverResponse | undefined} The fallback value to use if the content model is not found for any keys.
 * See {@link generateContentKey} if key generation is needed.
 * @param isPreview {boolean} Is this admin preview mode? Default is false.
 * @return {ContentResolverResponse | undefined} The content model or undefined if not found.
 */
const getContentModel = ({
  name,
  keyGen = {},
  contentModelContext,
  fallbackKeys = [],
  fallbackValue = undefined
}) => {
  const key = generateContentKey(name, keyGen);
  if (!contentModelContext || isPreview(keyGen)) {
    return undefined;
  }
  if (contentModelContext[key]) {
    return contentModelContext[key];
  }
  for (let i = 0; i < fallbackKeys.length; i++) {
    if (contentModelContext[fallbackKeys[i]]) {
      return contentModelContext[fallbackKeys[i]];
    }
  }
  return fallbackValue;
};

/**
 * Generate a content model key.
 * @param name {string} The name of the content model
 * @param keyGen {ContentModelKeyGen | {}} The key generation values. Optional.
 */
const generateContentKey = (name, keyGen = {}) => {
  if (!name) {
    throw new Error(
      'Content Model name is required to generate a content key.'
    );
  }
  let sanitizedName = '';
  for (let i = 0; i < name.length; i++) {
    // replace spaces with underscores
    if (name[i] === ' ') {
      sanitizedName += '_';
    }
    // replace any non-alphanumeric characters with nothing
    else if (name[i].match(/^[0-9a-zA-Z]+$/)) {
      sanitizedName += name[i];
    }
  }
  if (isNotBlank(keyGen, 'applicationId')) {
    sanitizedName += '-' + get(keyGen, 'applicationId');
  }
  if (isNotBlank(keyGen, 'currentStoreId')) {
    sanitizedName += '-' + get(keyGen, 'currentStoreId');
  }
  return sanitizedName + '-' + keyGen.locale;
};

function isNotBlank(obj, prop) {
  const val = get(obj, prop);
  return !isEmpty(val);
}

function isPreview(keyGen) {
  return get(keyGen, 'isPreview', false);
}

/**
 * @typedef {Object} ContentModelKeyGenValues
 * @property {ContentModelKeyGen} tenant - The tenant level key gen object
 * @property {ContentModelKeyGen | undefined} dealerNetwork - The dealer network level key gen object. Undefined if not in a dealer network context
 * @property {ContentModelKeyGen | undefined} currentStore - The current store level key gen object. Undefined if no store is selected.
 */
/**
 * Return content model key generation objects for the current context.
 * @return {ContentModelKeyGenValues | {}} The key generation objects.
 */
const useGetContentModelKeyGens = () => {
  const {
    application,
    dealerNetwork,
    currentApplication,
    dealerSelected,
    applicationThemeSource,
    resolving
  } = useNationalSiteContext();
  const { currentLocale } = useContext(LocaleContext);
  const previewContext = useContext(PreviewContext);
  let isPreview = false;
  if (previewContext && previewContext.length > 0) {
    isPreview = get(previewContext[0], 'isActive', false);
  }

  return useMemo(() => {
    if (resolving) {
      return {};
    }
    const { id: applicationId } = application || {};
    const commonProps = { locale: currentLocale, isPreview };
    const response = {};
    response.tenant = { applicationId: 'AURORA', ...commonProps };
    if (dealerNetwork) {
      if (dealerSelected) {
        response.dealerNetwork = {
          applicationId: get(applicationThemeSource, 'id'),
          ...commonProps
        };
      } else {
        response.dealerNetwork = { applicationId, ...commonProps };
      }
    }
    if (dealerSelected) {
      response.currentStore = {
        applicationId: get(applicationThemeSource, 'id'),
        currentStoreId: get(currentApplication, 'id'),
        ...commonProps
      };
    }
    return response;
  }, [
    application,
    dealerNetwork,
    currentApplication,
    dealerSelected,
    resolving,
    currentLocale,
    applicationThemeSource,
    isPreview
  ]);
};

export default ContentModelContext;

export {
  ContentModelProvider,
  addContentModel,
  getContentModel,
  generateContentKey,
  useGetContentModelKeyGens
};
