import { useContentInfo, useFetchContent } from 'app/content/hooks';
import { useMemo } from 'react';
import { get, isEmpty } from 'lodash';

function useFetchAboutUsContent() {
  const { aboutUsModelName } = useContentInfo();
  const aboutUsContent = useFetchContent({ modelName: aboutUsModelName });
  return useMemo(() => {
    if (!isEmpty(aboutUsContent)) {
      return {
        aboutSectionTitle: get(aboutUsContent, 'fields.aboutTitle.value'),
        aboutSection1: get(aboutUsContent, 'fields.aboutSection1.value'),
        aboutSection2: get(aboutUsContent, 'fields.aboutSection2.value')
      };
    } else {
      return {};
    }
  }, [aboutUsContent]);
}

export default useFetchAboutUsContent;
