/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';

import { Button, Icon } from 'app/common/components';
import { SiteContext } from 'app/common/contexts';
import { useCurrentApplication, useFormatMessage } from 'app/common/hooks';

import { TertiaryButton } from '../buttons';
import { NearestAvailableButton } from './components';
import messages from './ItemAvailability.messages';
import { useGetInventoryMessageForProduct } from 'app/common/components/ItemAvailability/hooks/useInventoryMessaging.js';

const ItemAvailability = ({ product, textSize, nearbyButtonSize }) => {
  const { setOpenStoreDropdown } = React.useContext(SiteContext);
  if (!textSize) {
    textSize = 'text-sm';
  }
  if (!nearbyButtonSize) {
    nearbyButtonSize = TertiaryButton.Size.SMALL;
  }
  const formatMessage = useFormatMessage();
  const { nationalSiteOrDealerNetwork } = useCurrentApplication() || {};
  const inventoryLabel = useGetInventoryMessageForProduct(product);

  return (
    <>
      {nationalSiteOrDealerNetwork ? (
        <>
          <Button
            className={classNames(
              'block mb-2 not-italic underline font-medium text-red-700 hover:text-red-800 hover:font-semibold text-left',
              textSize
            )}
            onClick={() => {
              setOpenStoreDropdown(true);
            }}
          >
            {formatMessage(messages.selectDealer)}
          </Button>
        </>
      ) : (
        <>
          <em
            className={classNames(
              'flex items-center mt-1 not-italic font-medium capitalize text-green-600',
              textSize
            )}
          >
            <Icon className="block mr-1" name="check" />
            {inventoryLabel}
          </em>
          <NearestAvailableButton
            product={product}
            nearbyButtonSize={nearbyButtonSize}
          />
        </>
      )}
    </>
  );
};

export default ItemAvailability;
export { ItemAvailability };
