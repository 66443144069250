/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import AuthProvider from 'app/auth/helpers/AuthProvider';
import { Environment } from 'app/common/services';
import { App, TenantGateway } from 'app/core/components';
import {
  CurrencyProvider,
  CustomerProvider,
  I18nProvider,
  PreviewProvider,
  ThemeProvider
} from 'app/core/helpers';

import messages from './messages';
import * as serviceWorker from './serviceWorker';

import './loadLocaleData';
import './loadIconData';

// imports all styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'styles/tailwind.css';
import 'paymentfont/css/paymentfont.css';
import ReactDOM from 'react-dom';
import { ContentModelProvider } from 'app/content/contexts/ContentModelContext.js';

const browserSupportsHistory = 'pushState' in window.history;

ReactDOM.render(
  <Router
    basename={Environment.get('public.url')}
    forceRefresh={!browserSupportsHistory}
  >
    <I18nProvider messages={messages}>
      <CurrencyProvider>
        <TenantGateway>
          <ThemeProvider>
            <AuthProvider>
              <CustomerProvider>
                <PreviewProvider>
                  <ContentModelProvider>
                    <App />
                  </ContentModelProvider>
                </PreviewProvider>
              </CustomerProvider>
            </AuthProvider>
          </ThemeProvider>
        </TenantGateway>
      </CurrencyProvider>
    </I18nProvider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
