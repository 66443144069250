import { useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  COMPLETE,
  IN_PROGRESS,
  PENDING
} from 'app/common/hooks/useRestApi/RequestStatus.js';
import { useRestApi } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils/index.js';
import useContentInfo from '../useContentInfo';
import { TenantContext } from 'app/common/contexts';

/**
 * Fetches content for the given modelName. This fetches from the content
 * service via catalog browse.
 * @param modelName {string} The name of the model to fetch. ContentModel#name
 * @param sendImmediately {boolean} If true, the request will be sent immediately
 * @return {ContentResolverResponse | RestApiState | {}} Returns the content object if sendImmediately is true, otherwise
 * returns the rest API
 */
function useFetchContent({ modelName, sendImmediately = true }) {
  const { browseContentItemEndpoint } = useContentInfo();
  const [content, setContent] = useState({});

  const {
    application,
    resolving: resolvingTenant,
    tenant
  } = useContext(TenantContext);

  const applicationReady = useMemo(() => {
    if (resolvingTenant) {
      setContent({});
    }
    return !isEmpty(tenant) && !isEmpty(application) && !resolvingTenant;
  }, [tenant, application, resolvingTenant]);

  const {
    error,
    exception,
    response,
    sendCallback: fetchContent,
    requestStatus,
    loading
  } = useRestApi(
    browseContentItemEndpoint,
    { params: { modelName: modelName } },
    false
  );

  useRequestContent({
    error,
    exception,
    response,
    requestStatus,
    content,
    fetchContent,
    applicationReady,
    sendImmediately,
    setContent
  });

  if (sendImmediately) {
    /** @type {ContentResolverResponse} */
    return content;
  }
  /** @type {RestApiState} */
  return {
    error,
    exception,
    response,
    sendCallback: fetchContent,
    requestStatus,
    loading
  };
}

const useRequestContent = ({
  error,
  exception,
  response,
  requestStatus,
  content,
  fetchContent,
  applicationReady,
  sendImmediately,
  setContent
}) => {
  useEffect(() => {
    if (!sendImmediately) {
      return;
    }
    if (requestStatus === IN_PROGRESS || !isEmpty(content)) {
      return;
    }

    if (error) {
      logError({ ...exception, when: 'Fetching Content' });
      return;
    }

    if (requestStatus === COMPLETE && isEmpty(content)) {
      const results = response.results;
      if (isEmpty(results)) {
        return;
      }

      setContent(results[0]);
      return;
    }

    if (applicationReady && requestStatus === PENDING) {
      fetchContent();
    }
  }, [
    error,
    exception,
    response,
    requestStatus,
    content,
    fetchContent,
    applicationReady,
    sendImmediately,
    setContent
  ]);
};

/**
 * @typedef {Object} ContentResolverResponse
 * @property {ContentItem[]} results - The content items returned by the resolver
 * @property {ContentItem[]} paginatedResults - Present instead of results if paging is used? No need for now
 * @property {string[]} namesNotFound - Names of the content items that were not found
 * @property {string[]} idsNotFound - IDs of the content items that were not found
 * @proper
 *
 */

/**
 * @typedef {Object} ContentModel
 * @property {string} id - The unique identifier of the content model
 * @property {string} name - The name of the content model
 * @property {string} uri - The URI of the content model if applicable.
 * @property {boolean} addressableByUri - Whether the content model is addressable by URI
 * @property {Object[]} fields - The fields of the content model
 */

/**
 * @typedef {Object} ContentItem
 * @property {string} id - The unique identifier of the content item
 * @property {string} name - The name of the content item
 * @property {string} uri - The URI of the content item if applicable.
 * @property {ContentModel} model - The content model for the content item
 * @property {Object.<string, ContentItemFieldData>} fields - The field data for this content
 * @property {Object.<string, Object>} attributes - The attributes for this content
 */

/**
 * @typedef {Object} ContentItemFieldData
 * @property {string} id - The unique identifier of the field data
 * @property {string} name - The name of the field data
 * @property {Object} value - The value of the field data
 * @property {Object.<string, any>}
 */
export default useFetchContent;
