/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { TenantContext } from '../../contexts';
import { ResolutionContextType } from '../../constants';
import { get } from 'lodash';

/**
 * @typedef {Object} NationalSiteContext
 * @property {boolean} nationalSite - Is the user is in a national site context? e.g. at ptg.com or fyx
 * @property {boolean} dealerSelected - Is the user currently has a dealer selected. They are either in a dealer microsite or have selected a dealer
 * from a national site context. In other words, they are able to checkout.
 * @property {boolean} useApplicationContent - Should the application's theme content should be used (colors, logos, etc.).
 * @property {Object | null} applicationThemeSource - If useApplicationContent is true, this is the application to use for theming.
 * @property {boolean} resolving - If the tenant information is still resolving. See {@link TenantContext#resolving}
 * @property {string} resolutionContext - The current {@link ResolutionContextType}
 * @property {boolean} partsToGo - If we're at partstogo.com. Some content fallbacks require knowledge of this.
 * @property {boolean} isWhiteLabel - If we are on a white label or national site that is not partsToGo.
 * @property {Object} nationalSiteHeaders - Headers describing the national site context.
 * @property {boolean} dealerNetwork - Are we in a dealer network context?
 * @property {boolean} nationalSiteOrDealerNetworkContext - Are we in a national site or dealer network context?
 */

/**
 * Returns an object with information about the current context to drive behavior around national sites (e.g. PTG, FYX).
 *
 * @returns {NationalSiteContext}
 */
const useNationalSiteContext = () => {
  const { resolving, application, resolutionContext, defaultApplication } =
    React.useContext(TenantContext);
  return React.useMemo(() => {
    let nationalSiteOrDealerNetworkContext = false;
    let dealerSelected = false;
    let useApplicationContent = false;
    let applicationThemeSource = {};
    let partsToGo = false;
    let isWhiteLabel = false;
    let dealerNetwork = false;
    let nationalSite = false;
    if (resolving) {
      // do nothing, still resolving
    } else if (resolutionContext === ResolutionContextType.DEALER) {
      dealerSelected = true;
      useApplicationContent = true;
      applicationThemeSource = application;
      isWhiteLabel = true;
    } else {
      nationalSiteOrDealerNetworkContext = isNationalSiteOrDealerNetwork(
        application,
        defaultApplication
      );
      dealerNetwork = isDealerNetwork(application, defaultApplication);
      nationalSite = isNationalSite(application, defaultApplication);
      dealerSelected = isDealerSelected(application);
      useApplicationContent = true;
      applicationThemeSource = dealerSelected
        ? defaultApplication
        : application;
      partsToGo = get(applicationThemeSource, 'id') === 'AURORA';
      isWhiteLabel = !partsToGo && nationalSiteOrDealerNetworkContext;
    }
    return {
      nationalSite: nationalSite,
      dealerSelected: dealerSelected,
      useApplicationContent: useApplicationContent,
      applicationThemeSource: applicationThemeSource,
      resolving: resolving,
      resolutionContext: resolutionContext,
      partsToGo: partsToGo,
      isWhiteLabel: isWhiteLabel,
      nationalSiteOrDealerNetworkContext: nationalSiteOrDealerNetworkContext,
      dealerNetwork: dealerNetwork,
      currentApplication: application,
      defaultApplication: defaultApplication || {},
      nationalSiteHeaders: nationalSiteHeaders(
        isWhiteLabel,
        applicationThemeSource,
        nationalSiteOrDealerNetworkContext,
        dealerNetwork
      )
    };
  }, [resolving, application, resolutionContext, defaultApplication]);
};

const nationalSiteHeaders = (
  isWhiteLabel,
  parentSite,
  inNationalSiteContext,
  isDealerNetwork
) => {
  let nationalSiteId = null;
  if (inNationalSiteContext && parentSite) {
    nationalSiteId = parentSite['id'];
  }
  return {
    'X-White-Label': isWhiteLabel,
    ...(isDealerNetwork && { 'X-Dealer-Network': isDealerNetwork }),
    ...(nationalSiteId && { 'X-National-Site-Id': nationalSiteId })
  };
};

const isNationalSiteOrDealerNetwork = (application, defaultApplication) => {
  const key = 'nationalSiteOrDealerNetwork';
  return get(application, key, false) || get(defaultApplication, key, false);
};

const isDealerNetwork = (application, defaultApplication) => {
  const key = 'dealerNetwork';
  return get(application, key, false) || get(defaultApplication, key, false);
};

const isNationalSite = (application, defaultApplication) => {
  const key = 'nationalSite';
  return get(application, key, false) || get(defaultApplication, key, false);
};

const isDealerSelected = application => {
  return !(
    get(application, 'nationalSite') || get(application, 'dealerNetwork')
  );
};

export default useNationalSiteContext;
